import React from 'react';
import './Business.css'

export class Business extends React.Component {
	render() {
		console.log(this.props);
		return (
			<div className="Business">
			  <div className="image-container">
			    <img src={this.props.business.imageSrc} alt=''/>
			  </div>
			  <div className="Business-information">
			    <div className="Business-address">
			      <h2>{this.props.business.category}</h2>
			    </div>
			    <div className="Business-reviews">
			      <h3>Price: {this.props.business.price}</h3>
			      <p>{Math.ceil(this.props.business.distance/1609.34 * 10 ) / 10} miles away</p>
			    </div>
			  </div>
			</div>
		);
	}
}