import React from 'react';
import './App.css';
import { BusinessList } from '../BusinessList/BusinessList';
import { SearchBar } from '../SearchBar/SearchBar';
import Yelp from '../../util/Yelp';
import FaSliders from 'react-icons/lib/fa/sliders';
import FaHeart from 'react-icons/lib/fa/heart';

export class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      businesses: [],
      settingsClosed: true,
      displayDefault: true 
    };
    this.searchYelp = this.searchYelp.bind(this);
    this.toggleSettings = this.toggleSettings.bind(this);
  }

  searchYelp(term,latitude,longitude,sortBy,price,distance) {
    Yelp.search(term, latitude, longitude, sortBy, price, distance).then(businesses => {
      this.setState({businesses: businesses});
      document.getElementById("welcome").style.display = "none";
    });
  }

  toggleSettings() {
    if(this.state.settingsClosed === false) {
      this.setState({settingsClosed: true});
      document.getElementById("mySidebar").style.width = "0";
    } else {
      this.setState({settingsClosed: false});
      var windowWidth = window.innerWidth-50;
      document.getElementById("mySidebar").style.width = windowWidth + "px";
    }
  }

  render() {
    return (
      <div className="App">
        <div id="mySidebar" className="sidebar">
          <SearchBar searchYelp={this.searchYelp} togglePanel={this.toggleSettings} panelState={this.state.settingsClosed} />
        </div>

        <div id="main">
          <div className="fixed-top">
          <nav className="navbar navbar-dark bg-success d-flex justify-content-between bd-highlight">
            <div className="p-2 bd-highlight header-icon"><a onClick={this.toggleSettings}><FaSliders /></a></div>
            <div className="p-2 bd-highlight">
              <h1>CvillEats</h1>
            </div>
            <div className="p-2 bd-highlight header-icon"><FaHeart /></div>
          </nav>
          </div>
          <BusinessList businesses={this.state.businesses} />
          <div id="welcome">
            <h2>Welcome to CvilleEats!</h2>
            <p>Click the settings icon above to modify your search criteria.</p>
          </div>
        </div>
      </div>
    );
  }
}
