import React from 'react';
import './SearchBar.css';

export class SearchBar extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			term: '',
			location: '',
			latitude: 38.03102161382791, 
			longitude: -78.47935348719426, 
			price: '1,2,3,4',
			distance: 20000,
			sortBy: true
		}

		this.handleTermChange = this.handleTermChange.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.handleSortByChange = this.handleSortByChange.bind(this);
    this.handlePriceChange = this.handlePriceChange.bind(this);
    this.handleDistanceChange = this.handleDistanceChange.bind(this);

		this.sortByOptions = {
			'Open Now': true, 
			'Open Anytime': false
		};

		this.priceOptions = {
			'Any price': '1,2,3,4', 
			'Price: $': '1',
			'Price: $$': '2',
			'Price: $$$': '3',
			'Price: $$$$': '4'
		};

		this.distanceOptions = {
			'Any distance': 20000, 
			'500 yards': 500,
			'1 mile': 1760,
			'2 miles': 3520,
			'5 miles': 8800
		};

	}

	getSortByClass(sortByOption) {
		if(this.state.sortBy === sortByOption) {
			return 'active';
		}
		return '';
	}

	handleSortByChange(sortByOption) {
    this.setState({sortBy: sortByOption});
  }

  handleTermChange(event) {
    this.setState({term: event.target.value});
  }

  handleLocationChange(event) {
    this.setState({location: event.target.value});
  }

  handlePriceChange(event) {
    this.setState({price: event.target.value});
  }

  handleDistanceChange(event) {
    this.setState({distance: event.target.value});
  }

  handleSearch(event) {
    this.props.searchYelp(this.state.term, this.state.latitude, this.state.longitude, this.state.sortBy, this.state.price, this.state.distance);

    event.preventDefault();
  }

  handleToggle(event) {
    this.props.togglePanel();

    event.preventDefault();
  }

	renderSortByOptions() {
    return Object.keys(this.sortByOptions).map(sortByOption => {
      let sortByOptionValue = this.sortByOptions[sortByOption];
      return (<li className={this.getSortByClass(sortByOptionValue)}
                  key={sortByOptionValue}
                  onClick={this.handleSortByChange.bind(this, sortByOptionValue)}>
                {sortByOption}
             </li>);
    });
  }

  renderPriceOptions() {
    return Object.keys(this.priceOptions).map(priceOption => {
      let priceOptionValue = this.priceOptions[priceOption];
      return (<option className={this.getSortByClass(priceOptionValue)}
                  key={priceOptionValue} value={priceOptionValue}>
                {priceOption}
             </option>);
    });
  }

  renderDistanceOptions() {
    return Object.keys(this.distanceOptions).map(distanceOption => {
      let distanceOptionValue = this.distanceOptions[distanceOption];
      return (<option className={this.getSortByClass(distanceOptionValue)}
                  key={distanceOptionValue} value={distanceOptionValue}>
                {distanceOption}
             </option>);
    });
  }

	render() {
		return(
			<div className={`SearchBar ${this.props.panelState ? "collapse" : ""}`}>
			  <div className="SearchBar-sort-options col-12">
			    <ul>
			      {this.renderSortByOptions()}
			    </ul>
			  </div>
			  <div className="SearchBar-fields">
			    <input className="form-control" placeholder="Search by keyword" onChange={this.handleTermChange} />
			    <div className="row">
				    <div className="col-6">
				    <select className="form-control" onChange={this.handlePriceChange} value={this.state.price}>
							{this.renderPriceOptions()}
				    </select>
				    </div>
						<div className="col-6">
				    <select className="form-control" onChange={this.handleDistanceChange} value={this.state.distance}>
							{this.renderDistanceOptions()}
				    </select>
				    </div>
				  </div>
			  </div>
			  <div className="SearchBar-submit">
			    <a href="#" className="btn btn-outline-light" onClick={this.handleToggle}>Close settings</a> 
			    <a href="#" className="btn btn-success" onClick={this.handleSearch}>Find my food!</a>
			  </div>
			</div>
		);
	}
}
